////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(57, 62, 66, 1);
}
.bg-secondaryColor {
  background-color: rgba(57, 62, 66, 1);
}
.bg-thirdColor {
  background-color: rgba(0, 0, 0, 1);
}
.bg-fourthColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-fifthColor {
  background-color: rgba(255, 255, 255, 0.8);
}
.bg-sixthColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-seventhColor {
  background-color: rgba(243, 235, 235, 1);
}
